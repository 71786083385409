$(function () {
  $(".sp-menu").click(function () {
    $(".header-nav").slideDown();
  });
  $(".close-menu").click(function () {
    $(".header-nav").slideUp();
  });

  if (window.location.hash.indexOf("#") >= 0) {
    $("html,body").animate(
      {
        scrollTop: $(window.location.hash).offset().top - 78 + "px",
      },
      300
    );
  } //主要修复评论定位不准确BUG
  $(".company-menu-list a[href^=\\#][href!=\\#]").click(function () {
    var target = document.getElementById(this.hash.slice(1));
    if (!target) return;
    var targetOffset = $(target).offset().top - 78;
    $("html,body").animate(
      {
        scrollTop: targetOffset,
      },
      300
    );
    return false;
  }); //主要修复评论定位不准确BUG
});

//回到顶部
window.goTop = () => {
  $("html,body").animate({ scrollTop: 0 });
}
